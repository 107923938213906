import Map from './Map'

export default class CustomerMap extends Map {
  initialize(containerID, { lat, lng }, customers, highlightCustomer = null) {
    let center = this.createCoordinates(lat, lng)

    let markers = []
    let highlightMarker = null

    // Highlight customer
    if (highlightCustomer) {
      // Make sure it's not passed to markers
      let customerIndex = customers.findIndex(customer => {
        return customer.lat === lat && customer.lng === lng
      })

      customers.splice(customerIndex, 1)

      // Highlight customer as blue
      highlightMarker = this.createMarkerFromCustomer(highlightCustomer, 'blue')
    }

    // Create markers with card
    markers = this.createMarkers(customers)

    if (highlightMarker) {
      markers.push(highlightMarker)
    }

    // Create
    this.createMap(containerID, center, markers)
  }

  createMap(containerID, center, markers) {
    // Initialize map with center
    this.initializeMap(containerID, center)

    // Create layer
    this.createInactiveLayer()

    // Create and set clusterer
    // this.createClusterer();

    this.addMarkers(markers)

    // Active layer
    this.activateLayer()
  }

  replaceMarkers(customers) {
    this.clearMap()

    this.addCustomersToMap(customers)
  }

  addCustomersToMap(customers) {
    let markers = []

    // Create markers with card
    markers = this.createMarkers(customers)

    this.addMarkers(markers)
  }

  createMarkers(customers) {
    let markers = []

    customers.forEach(customer => {
      let marker = this.createMarkerFromCustomer(customer)

      markers.push(marker)
    })

    return markers
  }

  createMarkerFromCustomer(customer, color = 'red') {
    let header = this.createLink(customer)
    let card = this.createCard(header)

    let point = this.createCoordinates(customer.lat, customer.lng)

    return this.createMarker(card, point, 'customer_' + customer.id, color)
  }

  createLink(customer) {
    let href = '/customers/' + customer.id

    return '<a href="' + href + '">' + customer.name + '</a>'
  }
}
