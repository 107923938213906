export const state = () => ({
  activities: [],
})

export const getters = {
  getAll: state => {
    return state.activities
  },
  get: state => activity_id => {
    return state.activities.find(
      activity => activity.id === parseInt(activity_id)
    )
  },
}

export const mutations = {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let activities = await this.$axios.$get('activities', {
      params: params,
      withCredentials: true,
    })

    commit('SET_ACTIVITIES', activities.data)

    return activities.data
  },
}
