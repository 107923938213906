export const state = () => ({
  // Customer is set to context (shown in topbar) and it's preselected when visiting different pages.
  customer: null,
  project: null,
})

export const getters = {
  getCustomer: state => {
    return state.customer
  },
  getProject: state => {
    return state.project
  },
  customerInContext: state => {
    return state.customer !== null
  },
  projectInContext: state => {
    return state.project !== null
  },
}

export const mutations = {
  SET_CUSTOMER_CONTEXT(state, customer) {
    state.customer = customer
  },
  SET_PROJECT_CONTEXT(state, project) {
    state.project = project
  },
  CLEAR_CUSTOMER_CONTEXT(state) {
    state.customer = null
    this.$localForage.removeItem('context.customer')
  },
  CLEAR_PROJECT_CONTEXT(state) {
    state.project = null
    this.$localForage.removeItem('context.project')
  },
}

export const actions = {
  setCustomer({ commit, getters, rootGetters }, customer_id) {
    // Clear project (could be from different customer)
    commit('CLEAR_PROJECT_CONTEXT')

    // Get customer
    let customer = rootGetters['customers/get'](customer_id)

    // Set to context
    commit('SET_CUSTOMER_CONTEXT', customer)

    // Persist to IDB
    this.$localForage.setItem('context.customer', customer)
  },
  async setProject({ dispatch, commit, getters, rootGetters }, project_id) {
    let project = rootGetters['projects/get'](project_id)

    // If there is none project, fetch him.
    if (!project) {
      project = await dispatch(
        'projects/fetch',
        {
          project_id: project_id,
        },
        { root: true }
      )
    }

    // If there is missing customer in context or both project and customer was set ...
    if (
      (getters.customerInContext && getters.projectInContext) ||
      !getters.customerInContext
    ) {
      // Set customer from project.

      let customer_id = project.customer_id
      let customer = rootGetters['customers/get'](customer_id)
      if (!customer) {
        customer = await dispatch(
          'customers/get',
          {
            customer_id: project.customer_id,
          },
          { root: true }
        )
      }

      // Set to context
      commit('SET_CUSTOMER_CONTEXT', customer)

      // Persist to IDB
      this.$localForage.setItem('context.customer', customer)
    }

    // Always set project
    commit('SET_PROJECT_CONTEXT', project)

    // Persist to IDB
    this.$localForage.setItem('context.project', project)
  },
  clearFromContext({ commit, getters }) {
    // If there is some project clear him first.
    if (getters.projectInContext) {
      commit('CLEAR_PROJECT_CONTEXT')
    } else {
      commit('CLEAR_CUSTOMER_CONTEXT')
    }
  },
  async loadFromIndexDB({ commit }) {
    // Get from IDB
    let customer = await this.$localForage.getItem('context.customer')
    let project = await this.$localForage.getItem('context.project')

    // Set to VUEX
    commit('SET_CUSTOMER_CONTEXT', customer)
    commit('SET_PROJECT_CONTEXT', project)
  },
}
