export const state = () => ({
  product_categories: [],
})

export const getters = {
  getAll: state => {
    return state.product_categories
  },
  get: state => product_id => {
    return state.product_categories.find(
      product_category => product_category.id === parseInt(product_id)
    )
  },
}

export const mutations = {
  SET_PRODUCT_CATEGORIES(state, product_categories) {
    state.product_categories = product_categories
  },
  PUSH_OR_REPLACE_PRODUCT_CATEGORIES(state, new_product_category) {
    let product_category_index = state.product_categories.findIndex(
      product_category =>
        product_category.id === parseInt(new_product_category.id)
    )

    if (product_category_index >= 0) {
      state.product_categories.splice(
        product_category_index,
        1,
        new_product_category
      )
    } else {
      state.product_categories.push(new_product_category)
    }
  },
  REMOVE_PRODUCT_CATEGORIES(state, product_category_id) {
    let product_category_index = state.product_categories.findIndex(
      product_category => product_category.id === parseInt(product_category_id)
    )

    state.product_categories.splice(product_category_index, 1)
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let product_categories = await this.$axios.$get('product_categories', {
      params: params,
      withCredentials: true,
    })

    commit('SET_PRODUCT_CATEGORIES', product_categories.data)

    return product_categories.data
  },
  async fetch({ commit }, { product_id, params = null }) {
    let product_category = await this.$axios.$get(
      'product_categories/' + product_id,
      {
        params: params,
        withCredentials: true,
      }
    )

    commit('PUSH_OR_REPLACE_PRODUCT_CATEGORIES', product_category.data)

    return product_category.data
  },
  async store({ commit }, product_category) {
    product_category = await this.$axios.$post(
      'product_categories',
      product_category,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_PRODUCT_CATEGORIES', product_category.data)

    return product_category.data
  },
  async update({ commit }, { product_category, product_id }) {
    product_category = await this.$axios.$put(
      'product_categories/' + product_id,
      product_category,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_PRODUCT_CATEGORIES', product_category.data)

    return product_category.data
  },
  async delete({ commit }, product_id) {
    await this.$axios.$delete('product_categories/' + product_id, {
      withCredentials: true,
    })

    commit('REMOVE_PRODUCT_CATEGORIES', product_id)
  },

  // TODO spec form store
  async storeForm({ commit }, product_category) {
    product_category = await this.$axios.$post(
      'specification-forms',
      product_category,
      { withCredentials: true }
    )

    return product_category.data
  },
}
