<template>
  <header class="o-header fixed-top">
    <div class="container">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-brand v-if="isTechnician" class="navbar-brand" to="/tasks">
          <img
            alt="Mobilní Technik"
            style="height: 40px"
            src="/images/svg/mobilni-technik-logo-short.svg"
          />
        </b-navbar-brand>

        <b-navbar-brand v-else class="navbar-brand" to="/customers">
          <img
            alt="Mobilní Technik"
            style="height: 40px"
            src="/images/svg/mobilni-technik-logo-short.svg"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template v-slot:default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav>
            <!-- Technician is allowed only on tasks page, it's pointless to show them. -->
            <b-nav-item
              exact
              class="mx-2"
              to="/tasks"
              v-if="($nuxt.isOnline || $nuxt.isOffline) && isSalesman()"
            >
              <div class="nav-icon nav-icon-tasks">Úkoly</div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/projects"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-projects">Projekty</div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/customers"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-customers">Zákazníci</div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/meeting_notes"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-meeting_notes">
                Zápisy z jednání
              </div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/business_cases"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-business_cases">
                Obchodní případy
              </div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/product_categories"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-products">Produkty</div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/customer_products"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-customer_products">
                Prodané zboží
              </div>
            </b-nav-item>
            <b-nav-item
              exact
              class="mx-2"
              to="/customers-map"
              v-if="$nuxt.isOnline && isSalesman()"
            >
              <div class="nav-icon nav-icon-customers-map">Mapa zákazníků</div>
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <div class="d-flex align-items-center mr-3">
              <div
                v-if="projectExists || customerExists"
                class="d-flex align-items-center border border-dashed border-gray-500 rounded text-dark bg-light px-2 py-1"
              >
                {{ getContextName | str_limit(25) }}

                <!-- Will be replaced with image.-->
                <img
                  src="/images/svg/ico-x.svg"
                  style="height: 18px"
                  alt=""
                  class="ml-2 cursor-pointer"
                  @click="clearFromContext"
                />
              </div>
            </div>

            <b-nav-item @click.prevent="logout">
              <div class="d-flex align-items-center">
                <span class="d-block d-lg-none mr-2">Odhlásit se</span>
                <img
                  src="/images/svg/ico-logout.svg"
                  alt="Odhlásit se"
                  style="height: 22px"
                  class="d-none d-lg-block"
                  title="Odhlásit se"
                />
              </div>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'topbar',
  methods: {
    async logout() {
      this.$auth.logout()

      await this.$store.dispatch('appClear')

      this.$auth.redirect('login')
      this.$router.push('/')
    },
    isTechnician() {
      return (
        this.$auth.user.role === 'technician' ||
        this.$auth.user.role === 'admin'
      )
    },
    isSalesman() {
      return (
        this.$auth.user.role === 'salesman' || this.$auth.user.role === 'admin'
      )
    },
    clearFromContext() {
      this.$store.dispatch('context/clearFromContext')
    },
  },
  computed: {
    ...mapGetters({
      getCustomer: 'context/getCustomer',
      getProject: 'context/getProject',
      customerExists: 'context/customerInContext',
      projectExists: 'context/projectInContext',
    }),
    getContextName() {
      if (this.customerExists && !this.projectExists) {
        return this.getCustomer.name
      } else if (this.projectExists) {
        return this.getProject.name
      }

      return null
    },
  },
}
</script>
