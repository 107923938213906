import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, max_value } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: (fieldName, placeholders) => {
    return `${fieldName} je povinné pole.`
  },
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
