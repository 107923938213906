<template>
  <main class="o-main py-5">
    <div class="container">
      <nuxt />
    </div>
  </main>
</template>

<script>
export default {
  name: 'login',
}
</script>

<style scoped></style>
