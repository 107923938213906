export const state = () => ({
  items: [],
})

export const getters = {
  getAll: state => {
    return state.items
  },
  get: state => id => {
    return state.items.find(item => item.id === parseInt(id))
  },
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  UPDATE_ITEM_AMOUNT(state, params) {
    let item = state.items.find(item => item.id === parseInt(params.id))

    item.amount = params.amount

    state.items.splice(state.items.indexOf(item), 1, item)

    this.$localForage.setItem('items', state.items)
  },
  PUSH_OR_REPLACE_ITEM(state, new_item) {
    let itemIndex = state.items.findIndex(
      item => item.id === parseInt(new_item.id)
    )

    if (itemIndex >= 0) {
      state.items.splice(itemIndex, 1, new_item)
    } else {
      state.items.push(new_item)
    }
  },
}

export const actions = {
  async fetchForOffline({ dispatch, commit }, params = null) {
    let items = await fetchAll({ axios: this.$axios, dispatch, commit }, params)

    this.$localForage.setItem('items', items)

    return items
  },
  async fetch({ dispatch, commit }, params = null) {
    return await fetchAll({ axios: this.$axios, dispatch, commit }, params)
  },
  async fetchNewOrModifiedForOffline({ dispatch, commit, getters }) {
    if (navigator.onLine) {
      let items = await this.$axios.$get('store_items', {
        params: {
          fetched_at: await dispatch('getLastFetchedAt', 'items', {
            root: true,
          }),
        },
        withCredentials: true,
      })

      // Push new items
      for (let item of items.data) {
        commit('PUSH_OR_REPLACE_ITEM', item)
      }

      // Persist them
      this.$localForage.setItem('items', getters.getAll)
      // Set timestamp of last fetching
      dispatch('setFetchedAt', 'items', { root: true })

      return items.data
    }
  },
  async loadFromIndexDB({ commit }) {
    let items = await this.$localForage.getItem('items')

    commit('SET_ITEMS', items)
  },
  async updateAmount({ commit }, params) {
    commit('UPDATE_ITEM_AMOUNT', params)
  },
}

async function fetchAll({ axios, dispatch, commit }, params) {
  if (navigator.onLine) {
    let items = await axios.$get('store_items', {
      params: params,
      withCredentials: true,
    })

    // Set items
    commit('SET_ITEMS', items.data)
    // Set timestamp of last fetching
    dispatch('setFetchedAt', 'items', { root: true })

    return items.data
  }
}
