export const state = () => ({
  categories: [],
})

export const getters = {
  getAll: state => {
    return state.categories
  },
  get: state => category_id => {
    return state.categories.find(
      category => category.id === parseInt(category_id)
    )
  },
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let categories = await this.$axios.$get('customer_categories', {
      params: params,
      withCredentials: true,
    })

    commit('SET_CATEGORIES', categories.data)

    return categories.data
  },
}
