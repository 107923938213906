export const state = () => ({
  users: [],
})

export const getters = {
  getAll: state => {
    return state.users
  },
  get: state => id => {
    return state.users.find(user => user.id === parseInt(id))
  },
  getDealers: state => {
    return state.users.filter(user => user.role === 'salesman')
  },
  getDealer: state => id => {
    return state.users.find(
      user => user.role === 'salesman' && user.id === parseInt(id)
    )
  },
}

export const mutations = {
  SET_USERS(state, users) {
    state.users = users
  },
  PUSH_OR_REPLACE_USER(state, new_user) {
    let userIndex = state.users.findIndex(
      user => user.id === parseInt(new_user.id)
    )

    if (userIndex >= 0) {
      state.users.splice(userIndex, 1, new_user)
    } else {
      state.users.push(new_user)
    }
  },
}

export const actions = {
  async fetchForOffline({ dispatch, commit }, params = null) {
    let users = await fetchAll({ axios: this.$axios, dispatch, commit }, params)

    this.$localForage.setItem('users', users)

    return users
  },
  async fetch({ dispatch, commit }, params = null) {
    return await fetchAll({ axios: this.$axios, dispatch, commit }, params)
  },
  async fetchNewOrModifiedForOffline({ dispatch, commit, getters }) {
    if (navigator.onLine) {
      let users = await this.$axios.$get('users', {
        params: {
          fetched_at: await dispatch('getLastFetchedAt', 'users', {
            root: true,
          }),
        },
        withCredentials: true,
      })

      // Push new users
      for (let user of users.data) {
        commit('PUSH_OR_REPLACE_USER', user)
      }

      // Persist them
      this.$localForage.setItem('users', getters.getAll)
      // Set timestamp of last fetching
      dispatch('setFetchedAt', 'users', { root: true })

      return users.data
    }
  },
  async loadFromIndexDB({ commit }) {
    let users = await this.$localForage.getItem('users')

    commit('SET_USERS', users)
  },
}

async function fetchAll({ axios, dispatch, commit }, params) {
  if (navigator.onLine) {
    let users = await axios.$get('users', {
      params: params,
      withCredentials: true,
    })

    // Set users
    commit('SET_USERS', users.data)
    // Set timestamp of last fetching
    dispatch('setFetchedAt', 'users', { root: true })

    return users.data
  }
}
