export const state = () => ({
  meeting_notes: [],
})

export const getters = {
  getAll: state => {
    return state.meeting_notes
  },
  get: state => meeting_note_id => {
    return state.meeting_notes.find(
      meeting_note => meeting_note.id === parseInt(meeting_note_id)
    )
  },
}

export const mutations = {
  SET_MEETING_NOTES(state, meeting_notes) {
    state.meeting_notes = meeting_notes
  },
  PUSH_OR_REPLACE_MEETING_NOTE(state, new_meeting_note) {
    let meeting_note_index = state.meeting_notes.findIndex(
      meeting_note => meeting_note.id === parseInt(new_meeting_note.id)
    )

    if (meeting_note_index >= 0) {
      state.meeting_notes.splice(meeting_note_index, 1, new_meeting_note)
    } else {
      state.meeting_notes.push(new_meeting_note)
    }
  },
  REMOVE_MEETING_NOTE(state, meeting_note_id) {
    let meeting_note_index = state.meeting_notes.findIndex(
      meeting_note => meeting_note.id === parseInt(meeting_note_id)
    )

    state.meeting_notes.splice(meeting_note_index, 1)
  },
}

export const actions = {
  async fetch({ commit }, params = null) {
    let meeting_notes = await this.$axios.$get('meetings', {
      params: params,
      withCredentials: true,
    })

    commit('SET_MEETING_NOTES', meeting_notes.data)

    return meeting_notes.data
  },
  async store({ commit }, meeting_note) {
    meeting_note = await this.$axios.$post('meetings', meeting_note, {
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_MEETING_NOTE', meeting_note.data)

    return meeting_note.data
  },
  async update({ commit }, { meeting_note, meeting_note_id }) {
    meeting_note = await this.$axios.$put(
      'meetings/' + meeting_note_id,
      meeting_note,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_MEETING_NOTE', meeting_note.data)

    return meeting_note.data
  },
  async delete({ commit }, meeting_note_id) {
    await this.$axios.$delete('meetings/' + meeting_note_id, {
      withCredentials: true,
    })

    commit('REMOVE_MEETING_NOTE', meeting_note_id)
  },
}
