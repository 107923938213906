export const state = () => ({
  customers: [],
})

export const getters = {
  getAll: state => {
    return state.customers
  },
  get: state => customer_id => {
    return state.customers.find(
      customer => customer.id === parseInt(customer_id)
    )
  },
}

export const mutations = {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers
  },
  PUSH_OR_REPLACE_CUSTOMER(state, new_customer) {
    let customerIndex = state.customers.findIndex(
      customer => customer.id === parseInt(new_customer.id)
    )

    if (customerIndex >= 0) {
      state.customers.splice(customerIndex, 1, new_customer)
    } else {
      state.customers.push(new_customer)
    }
  },
}

export const actions = {
  // TODO This should be fetch
  async get({ commit }, { customer_id, params = null }) {
    let customer = await this.$axios.$get('customers/' + customer_id, {
      params: params,
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_CUSTOMER', customer.data)

    return customer.data
  },
  // TODO This is like fetchAll
  // TODO Issue here: In offline it works well. In online too.
  // TODO But if I fetch in online mode e.g. in customers, customer employees I am removing already
  // TODO fetched files from offline mode (tasks).

  // TODO Solution: I could split those functions to two separate (fetchForOffline, fetch)
  // TODO Difference would be that in fetchForOffline would save data to localForage.
  async fetchForOffline({ dispatch, commit }, params = null) {
    let customers = await fetchAll(
      { axios: this.$axios, dispatch, commit },
      params
    )

    this.$localForage.setItem('customers', customers)

    return customers
  },
  async fetch({ dispatch, commit }, params = null) {
    return await fetchAll({ axios: this.$axios, dispatch, commit }, params)
  },
  // TODO This is like fetchAllModified
  async fetchNewOrModifiedForOffline(
    { commit, getters, dispatch, rootGetters },
    params = {}
  ) {
    if (navigator.onLine) {
      params.fetched_at = await dispatch('getLastFetchedAt', 'customers', {
        root: true,
      })

      let customers = await this.$axios.$get('customers', {
        params: params,
        withCredentials: true,
      })
      // Push new customers
      for (let customer of customers.data) {
        commit('PUSH_OR_REPLACE_CUSTOMER', customer)
      }
      // Persist them
      this.$localForage.setItem('customers', getters['getAll'])
      // Set timestamp of last fetching
      dispatch('setFetchedAt', 'customers', { root: true })

      return customers.data
    }
  },
  async update({ commit }, { customer, customer_id }) {
    customer = await this.$axios.$put('customers/' + customer_id, customer, {
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_CUSTOMER', customer.data)

    return customer.data
  },
  async loadFromIndexDB({ commit }) {
    let customers = await this.$localForage.getItem('customers')

    commit('SET_CUSTOMERS', customers)
  },
}

async function fetchAll({ axios, dispatch, commit }, params) {
  if (navigator.onLine) {
    let customers = await axios.$get('customers', {
      params: params,
      withCredentials: true,
    })
    // Set customers
    commit('SET_CUSTOMERS', customers.data)
    // Set timestamp of last fetching
    dispatch('setFetchedAt', 'customers', { root: true })

    return customers.data
  }
}
