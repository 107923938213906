export const state = () => ({
  // State of initialization of whole project (from server).
  // After first global fetching set to true (also in IDB).
  // When attempting again, only data by dates below will be fetched.
  initialized: false,

  // Last date of fetching current data
  lastFetchedAt: {
    tasks: null,
    customers: null,
    employees: null,
    users: null,
    items: null,
  },
  // For app initialization => loading and user feedback
  initialization: {
    in_progress: false,
    message: 'Přihlásit se',
  },
  errorMessage: null,
})

export const getters = {
  isAppInitialized: state => {
    return state.initialized
  },
  // getLastFetchedAt: state => name => {
  //     console.log(this);
  //
  //     return this.$localForage.getItem(name + 'FetchedAt');
  // },
  getInitializationMessage: state => {
    return state.initialization.message
  },
  isInitializationInProgress: state => {
    return state.initialization.in_progress
  },
  getErrorMessage: state => {
    return state.errorMessage
  },
}

export const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  UNINITIALIZED(state) {
    state.initialized = false
  },
  SET_INITIALIZATION(state, bool) {
    state.initialization.in_progress = bool
    state.initialization.message = 'Přihlásit se'
  },
  SET_INITIALIZATION_MESSAGE(state, message) {
    state.initialization.message = message
  },
  SET_FETCHED_AT(state, name) {
    let date = new Date().toISOString()
    // this.$localForage.setItem(name + 'FetchedAt', date);
    // state.lastFetchedAt[name] = date;
  },
  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error
  },
}

export const actions = {
  async appInit({ dispatch, commit }) {
    commit('SET_INITIALIZATION', true)

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji zákazníky')
    let customersParams = {}
    if (process.env.APP_ENV === 'local') {
      customersParams.page = 1
    }

    await dispatch('customers/fetchForOffline', customersParams)

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji zaměstnance')
    let employeesParams = {}
    // if (process.env.APP_ENV === 'local') {
    //     employeesParams.page = 1;
    // }
    await dispatch('employees/fetchForOffline', employeesParams)

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji položky')
    await dispatch('items/fetchForOffline')

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji úkoly')
    await dispatch('tasks/fetchForOffline')

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji uživatele')
    await dispatch('users/fetchForOffline')

    // Clear forms
    let formKeys = await dispatch('getFormKeys')
    await dispatch('clearForms', formKeys)

    commit('SET_INITIALIZATION', false)

    commit('INITIALIZED')
    this.$localForage.setItem('initialized', true)
  },
  async appClear({ dispatch, commit }) {
    // Clear IndexedDB
    console.log('Clearing customers ...')
    await this.$localForage.removeItem('customers')
    console.log('Clearing employees ...')
    await this.$localForage.removeItem('employees')
    console.log('Clearing customer_employees ...')
    await this.$localForage.removeItem('customer_employees')
    console.log('Clearing items ...')
    await this.$localForage.removeItem('items')
    console.log('Clearing tasks ...')
    await this.$localForage.removeItem('tasks')
    console.log('Clearing users ...')
    await this.$localForage.removeItem('users')
    console.log('Clearing auth user ...')
    await this.$localForage.removeItem('auth-user')


    // Clear forms
    let formKeys = await dispatch('getFormKeys')
    await dispatch('clearForms', formKeys)

    commit('UNINITIALIZED')
    this.$localForage.setItem('initialized', false)
  },
  async appUpdate({ dispatch, commit }) {
    commit('SET_INITIALIZATION', true)

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji zákazníky')
    let customers = await dispatch('customers/fetchNewOrModifiedForOffline', {
      params: {
        limit: 100,
      },
    })

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji zaměstnance')
    let employees = await dispatch('employees/fetchNewOrModifiedForOffline', {
      params: {
        limit: 100,
      },
    })

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji položky')
    let items = await dispatch('items/fetchNewOrModifiedForOffline')

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji úkoly')
    let tasks = await dispatch('tasks/fetchNewOrModifiedForOffline')

    commit('SET_INITIALIZATION_MESSAGE', 'Synchronizuji uživatele')
    let users = await dispatch('users/fetchNewOrModifiedForOffline')

    // Clear form when customer, employee, item, task or user matches
    let formKeys = await dispatch('getFormKeys')

    dispatch('clearFormsByMatch', {
      formKeys,
      customers,
      employees,
      items,
      tasks,
      users,
    })

    commit('SET_INITIALIZATION', false)
  },
  async appLoadFromIDB({ dispatch, commit }) {
    commit('SET_INITIALIZATION', true)

    commit('SET_INITIALIZATION_MESSAGE', 'Načítám zákazníky')
    await dispatch('customers/loadFromIndexDB')

    commit('SET_INITIALIZATION_MESSAGE', 'Načítám zaměstnance')
    await dispatch('employees/loadFromIndexDB')

    commit('SET_INITIALIZATION_MESSAGE', 'Načítám položky')
    await dispatch('items/loadFromIndexDB')

    commit('SET_INITIALIZATION_MESSAGE', 'Načítám úkoly')
    await dispatch('tasks/loadFromIndexDB')

    commit('SET_INITIALIZATION_MESSAGE', 'Načítám uživatele')
    await dispatch('users/loadFromIndexDB')

    commit('SET_INITIALIZATION', false)
    commit('INITIALIZED')
  },
  setFetchedAt({ commit }, name) {
    let date = new Date().toISOString()

    this.$localForage.setItem(name + 'FetchedAt', date)

    // commit('SET_FETCHED_AT', date)
  },
  getLastFetchedAt({ commit, getters }, name) {
    return this.$localForage.getItem(name + 'FetchedAt')
  },
  async clearForms({ commit, getters }, formKeys) {
    formKeys.filter(key => {
      this.$localForage.removeItem(key)
    })
  },
  async getFormKeys({ commit, getters }) {
    // tasks.create, tasks.id.edit
    let keys = await this.$localForage.keys()
    let regex = /tasks\.edit\..*\.form/g

    return keys.filter(key => {
      // When it's create or edit form, remove them from indexDB
      if (key.includes('tasks.create.form') || key.match(regex)) {
        return key
      }
    })
  },
  async clearFormsByMatch(
    { commit, getters },
    { formKeys, customers, employees, items, tasks, users }
  ) {
    // customers => customer_id
    // employees => customer_employee_id
    // items => store_items[].id
    // tasks => tasks[].id
    // users => nothing to match

    await formKeys.filter(async key => {
      let form = await this.$localForage.getItem(key)

      let customerMatches = customers.findIndex(
        c => parseInt(c.id) === parseInt(form.customer_id)
      )
      let employeeMatches = employees.findIndex(
        e => parseInt(e.id) === parseInt(form.customer_employee_id)
      )
      let itemMatches = items.findIndex(i => form.store_items.includes(i.id))
      let taskMatches = tasks.findIndex(t => form.tasks.includes(t.id))

      if (
        customerMatches >= 0 ||
        employeeMatches >= 0 ||
        itemMatches >= 0 ||
        taskMatches >= 0
      ) {
        await this.$localForage.removeItem(key)
      }
    })
  },

  async clearErrors({ commit }) {
    console.log('clearErrors')
    commit('SET_ERROR_MESSAGE', null)
  },
}
