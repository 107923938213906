export default function ({ store, $auth, route, router, $localForage }) {
  // This is same as appInit (except it's triggered on all pages.
  // Primary use is for context (loadFromIndexDB)

  return new Promise(async (resolve, reject) => {
    // If I am online
    if (navigator.onLine) {
      // console.log('Online');
      // Logged in
      if ($auth.loggedIn) {
        // console.log('LoggedIn');
        await store.dispatch('context/loadFromIndexDB')

        // App is initialized
        if (store.getters['isAppInitialized']) {
          // console.log('Already initilaized');
          // Fetch only new or modified records
          resolve()
        } else {
          // console.log('Before initialization');
          // Page was reloaded, but IDB holds the data
          if (await $localForage.getItem('initialized')) {
            // console.log('Page was reloaded (VUEX empty)');

            resolve()
          } else {
            // Fetch whole app
            resolve()
          }
        }
      } else {
        // console.log('NOT logged in');
        resolve()
      }
    } else {
      // Offline
      // console.log('Offline');
      // Logged In
      if ($auth.loggedIn) {
        // console.log('LoggedIn');
        await store.dispatch('context/loadFromIndexDB')

        // App is initialized
        if (store.getters['isAppInitialized']) {
          // console.log('Already initialized');
          // Vuex is filled. We are got to go.

          resolve()
        } else {
          // console.log('Before initialization');

          // Page was reloaded, but IDB holds the data
          if (await $localForage.getItem('initialized')) {
            // console.log('Page was reloaded (VUEX empty)');

            resolve()
          } else {
            // IDB was cleared. App is gone. Can't fetch anything from outside.
            // Well, redirect to login ?
            router.push('/')
            resolve()
          }
        }
      } else {
        // console.log('NOT logged in');
        // NOTHING
        resolve()
      }
    }
  })
}
