export const state = () => ({
  tasks: [],
})

export const getters = {
  getAll: state => {
    return state.tasks
  },
  get: state => task_id => {
    return state.tasks.find(task => task.id === parseInt(task_id))
  },
  getByTechnician: state => (technician_id, current_task_id) => {
    return state.tasks.filter(
      task =>
        parseInt(task.user_id) === parseInt(technician_id) &&
        task.deleted !== true &&
        task.id !== parseInt(current_task_id)
    )
  },
}

export const mutations = {
  SET_TASKS(state, tasks) {
    state.tasks = tasks
  },
  UPDATE_TASK(state, new_task) {
    let task = state.tasks.find(task => task.id === parseInt(new_task.id))

    // Apply new_task to task
    for (let key in new_task) {
      task[key] = new_task[key]
    }

    state.tasks.splice(state.tasks.indexOf(task), 1, task)
  },
  PUSH_OR_REPLACE_TASK(state, new_task) {
    let taskIndex = state.tasks.findIndex(
      task => task.id === parseInt(new_task.id)
    )

    if (taskIndex >= 0) {
      state.tasks.splice(taskIndex, 1, new_task)
    } else {
      state.tasks.push(new_task)
    }
  },
  REMOVE_TASK(state, task_id) {
    let taskIndex = state.tasks.findIndex(task => task.id === parseInt(task_id))

    state.tasks.splice(taskIndex, 1)
  },
}

export const actions = {
  async fetchForOffline({ dispatch, commit }, params = null) {
    let tasks = await fetchAll({ axios: this.$axios, dispatch, commit }, params)

    this.$localForage.setItem('tasks', tasks)

    return tasks
  },
  async fetch({ dispatch, commit }, params = null) {
    return await fetchAll({ axios: this.$axios, dispatch, commit }, params)
  },
  async fetchNewOrModifiedForOffline({ dispatch, commit, getters }) {
    if (navigator.onLine) {
      let tasks = await this.$axios.$get('tasks', {
        params: {
          fetched_at: await dispatch('getLastFetchedAt', 'tasks', {
            root: true,
          }),
        },
        withCredentials: true,
      })

      // Push new tasks
      for (let task of tasks.data) {
        commit('PUSH_OR_REPLACE_TASK', task)

        // Clear predefined tasks from local storage
        await this.$localForage.removeItem('tasks.edit.' + task.id + '.form')
      }

      // Persist them
      this.$localForage.setItem('tasks', getters.getAll)
      // Set timestamp of last fetching
      dispatch('setFetchedAt', 'tasks', { root: true })

      return tasks.data
    }
  },
  async store({ commit, getters }, params) {
    await this.$axios.$post('tasks', params, { withCredentials: true })

    if (params.task_id) {
      commit('REMOVE_TASK', params.task_id)
    }
  },
  async loadFromIndexDB({ commit }) {
    let tasks = await this.$localForage.getItem('tasks')

    commit('SET_TASKS', tasks)
  },
  removeSoftly({ commit }, task) {
    task.deleted = true

    commit('UPDATE_TASK', task)
  },
  undoRemoveSoftly({ commit }, task) {
    task.deleted = false

    commit('UPDATE_TASK', task)
  },
}

async function fetchAll({ axios, dispatch, commit }, params) {
  if (navigator.onLine) {
    let tasks = await axios.$get('tasks', {
      params: params,
      withCredentials: true,
    })

    // Set tasks
    commit('SET_TASKS', tasks.data)
    // Set timestamp of last fetching
    dispatch('setFetchedAt', 'tasks', { root: true })

    return tasks.data
  }
}
