import Vue from 'vue'

Vue.filter('str_limit', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substr(0, size) + '...'
})

Vue.filter('format_price', function (value) {
  if (!value) return ''

  value = Number(value)

  return value.toFixed(2) + ' Kč'
})

Vue.filter(
  'format_number',
  function (value, floatFractions = 2, intFractions = 0) {
    if (!value) return ''

    value = Number(value)

    if (Number.isInteger(value)) {
      value = value.toFixed(intFractions)
    } else {
      value = value.toFixed(floatFractions)
    }

    return value
  }
)
