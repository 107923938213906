<template>
  <div>
    <div
      v-if="isLocal"
      class="position-absolute"
      style="top: 0; right: 0; z-index: 10000"
    >
      Zákazníci jsou načteni v omezeném počtu.
    </div>

    <topbar></topbar>

    <main class="o-main">
      <div v-if="getErrorMessage" class="alert alert-danger mb-0" role="alert">
        <div class="container">
          {{ getErrorMessage }}
        </div>
      </div>

      <alerts></alerts>

      <div class="container">
        <nuxt />
      </div>
    </main>
  </div>
</template>

<script>
import Topbar from '../components/topbar'
import Navbar from '../components/navbar'
import Alerts from '../components/alerts'
import { mapGetters } from 'vuex'

export default {
  components: { Alerts, Navbar, Topbar },
  middleware: ['before-auth', 'auth'],
  data() {
    return {
      isLocal: false,
    }
  },
  mounted() {
    if (process.env.APP_ENV === 'local') {
      this.isLocal = true
    }
  },
  computed: {
    ...mapGetters({
      getErrorMessage: 'getErrorMessage',
    }),
  },
}
</script>
