import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c463aff8 = () => interopDefault(import('../pages/business_cases/index.vue' /* webpackChunkName: "pages/business_cases/index" */))
const _dbd0c90a = () => interopDefault(import('../pages/customer_products/index.vue' /* webpackChunkName: "pages/customer_products/index" */))
const _6222ea2b = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _3f22150c = () => interopDefault(import('../pages/customers-map/index.vue' /* webpackChunkName: "pages/customers-map/index" */))
const _60a87a13 = () => interopDefault(import('../pages/meeting_notes/index.vue' /* webpackChunkName: "pages/meeting_notes/index" */))
const _042680ec = () => interopDefault(import('../pages/product_categories/index.vue' /* webpackChunkName: "pages/product_categories/index" */))
const _42b2f6ba = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _ec4ca8b8 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _43ab233a = () => interopDefault(import('../pages/business_cases/create.vue' /* webpackChunkName: "pages/business_cases/create" */))
const _9b07567a = () => interopDefault(import('../pages/employees/create.vue' /* webpackChunkName: "pages/employees/create" */))
const _47c9d9ea = () => interopDefault(import('../pages/meeting_notes/create.vue' /* webpackChunkName: "pages/meeting_notes/create" */))
const _3b602b44 = () => interopDefault(import('../pages/projects/create.vue' /* webpackChunkName: "pages/projects/create" */))
const _4ddfd8cc = () => interopDefault(import('../pages/tasks/create.vue' /* webpackChunkName: "pages/tasks/create" */))
const _485610d4 = () => interopDefault(import('../pages/customers/_id/index.vue' /* webpackChunkName: "pages/customers/_id/index" */))
const _1c7dfc0f = () => interopDefault(import('../pages/products/_id/index.vue' /* webpackChunkName: "pages/products/_id/index" */))
const _4b5daf06 = () => interopDefault(import('../pages/business_cases/_id/edit.vue' /* webpackChunkName: "pages/business_cases/_id/edit" */))
const _4cc7c874 = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _1684680e = () => interopDefault(import('../pages/meeting_notes/_id/edit.vue' /* webpackChunkName: "pages/meeting_notes/_id/edit" */))
const _cbbc0f62 = () => interopDefault(import('../pages/product_categories/_id/form.vue' /* webpackChunkName: "pages/product_categories/_id/form" */))
const _8e3753f2 = () => interopDefault(import('../pages/projects/_id/edit.vue' /* webpackChunkName: "pages/projects/_id/edit" */))
const _ab88fe46 = () => interopDefault(import('../pages/tasks/_id/edit.vue' /* webpackChunkName: "pages/tasks/_id/edit" */))
const _00c385b6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/business_cases",
    component: _c463aff8,
    name: "business_cases"
  }, {
    path: "/customer_products",
    component: _dbd0c90a,
    name: "customer_products"
  }, {
    path: "/customers",
    component: _6222ea2b,
    name: "customers"
  }, {
    path: "/customers-map",
    component: _3f22150c,
    name: "customers-map"
  }, {
    path: "/meeting_notes",
    component: _60a87a13,
    name: "meeting_notes"
  }, {
    path: "/product_categories",
    component: _042680ec,
    name: "product_categories"
  }, {
    path: "/projects",
    component: _42b2f6ba,
    name: "projects"
  }, {
    path: "/tasks",
    component: _ec4ca8b8,
    name: "tasks"
  }, {
    path: "/business_cases/create",
    component: _43ab233a,
    name: "business_cases-create"
  }, {
    path: "/employees/create",
    component: _9b07567a,
    name: "employees-create"
  }, {
    path: "/meeting_notes/create",
    component: _47c9d9ea,
    name: "meeting_notes-create"
  }, {
    path: "/projects/create",
    component: _3b602b44,
    name: "projects-create"
  }, {
    path: "/tasks/create",
    component: _4ddfd8cc,
    name: "tasks-create"
  }, {
    path: "/customers/:id",
    component: _485610d4,
    name: "customers-id"
  }, {
    path: "/products/:id",
    component: _1c7dfc0f,
    name: "products-id"
  }, {
    path: "/business_cases/:id/edit",
    component: _4b5daf06,
    name: "business_cases-id-edit"
  }, {
    path: "/employees/:id?/edit",
    component: _4cc7c874,
    name: "employees-id-edit"
  }, {
    path: "/meeting_notes/:id/edit",
    component: _1684680e,
    name: "meeting_notes-id-edit"
  }, {
    path: "/product_categories/:id/form",
    component: _cbbc0f62,
    name: "product_categories-id-form"
  }, {
    path: "/projects/:id/edit",
    component: _8e3753f2,
    name: "projects-id-edit"
  }, {
    path: "/tasks/:id/edit",
    component: _ab88fe46,
    name: "tasks-id-edit"
  }, {
    path: "/",
    component: _00c385b6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
