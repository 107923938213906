export const state = () => ({
  alerts: [], // {type: 'error | success', text: 'text'}
})

export const mutations = {
  add(state, item) {
    state.alerts.push(item)
  },
  close(state, item) {
    state.alerts = state.alerts.filter(i => i.text !== item.text)
  },
  closeAll(state) {
    state.alerts = []
    state.alerts.splice(0)
  },
}

export const getters = {
  getAll(state) {
    return state.alerts
  },
}

export const actions = {
  add({ commit, dispatch }, item) {
    commit('add', item)
    setTimeout(() => {
      dispatch('alerts/close', item)
    }, 10000)
  },

  closeAll({ commit }) {
    console.log('closeAll')
    commit('closeAll')
  },
}
