export const state = () => ({
  projects: [],
})

export const getters = {
  getAll: state => {
    return state.projects
  },
  get: state => project_id => {
    return state.projects.find(project => project.id === parseInt(project_id))
  },
}

export const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  PUSH_OR_REPLACE_PROJECT(state, new_project) {
    let project_index = state.projects.findIndex(
      project => project.id === parseInt(new_project.id)
    )

    if (project_index >= 0) {
      state.projects.splice(project_index, 1, new_project)
    } else {
      state.projects.push(new_project)
    }
  },
  REMOVE_PROJECT(state, project_id) {
    let project_index = state.projects.findIndex(
      project => project.id === parseInt(project_id)
    )

    state.projects.splice(project_index, 1)
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let projects = await this.$axios.$get('projects', {
      params: params,
      withCredentials: true,
    })

    commit('SET_PROJECTS', projects.data)

    return projects.data
  },
  async fetch({ commit }, { project_id, params = null }) {
    let project = await this.$axios.$get('projects/' + project_id, {
      params: params,
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_PROJECT', project.data)

    return project.data
  },
  async store({ commit }, project) {
    project = await this.$axios.$post('projects', project, {
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_PROJECT', project.data)

    return project.data
  },
  async update({ commit }, { project, project_id }) {
    project = await this.$axios.$put('projects/' + project_id, project, {
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_PROJECT', project.data)

    return project.data
  },
  async delete({ commit }, project_id) {
    await this.$axios.$delete('projects/' + project_id, {
      withCredentials: true,
    })

    commit('REMOVE_PROJECT', project_id)
  },
}
