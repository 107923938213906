<template>
  <div class="pb-2">
    <div
      v-for="(alert, index) in alerts"
      :key="index"
      class="alert alert-dismissible fade show"
      role="alert"
      :class="{
        'alert-success': alert.type === 'success',
        'alert-danger': alert.type === 'error',
      }"
    >
      <div class="container">
        <!--            <strong>Error:</strong> -->
        <span class="alert-message">{{ alert.text }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div v-if="$nuxt.isOffline" class="alert alert-danger mb-0" role="alert">
      <div class="container">
        Aplikace není připojena k internetu, zobrazovaná data nemusí být
        aktuální.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alerts',
  computed: {
    alerts() {
      return this.$store.getters['alerts/getAll']
    },
  },
}
</script>

<style scoped></style>
