export default function ({ store, $auth, redirect, $localForage }) {
  return new Promise(async (resolve, reject) => {
    let user = await $localForage.getItem('auth-user')
    // console.log('before auth: ', user);

    if (user) {
      $auth.setUser(user)
    } else {
      $auth.setUser(false)
    }

    resolve()
  })
}
