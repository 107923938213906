export const state = () => ({
  business_cases: [],
})

export const getters = {
  getAll: state => {
    return state.business_cases
  },
  get: state => business_case_id => {
    return state.business_cases.find(
      business_case => business_case.id === parseInt(business_case_id)
    )
  },
}

export const mutations = {
  SET_BUSINESS_CASES(state, business_cases) {
    state.business_cases = business_cases
  },
  PUSH_OR_REPLACE_BUSINESS_CASE(state, new_business_case) {
    let business_case_index = state.business_cases.findIndex(
      business_case => business_case.id === parseInt(new_business_case.id)
    )

    if (business_case_index >= 0) {
      state.business_cases.splice(business_case_index, 1, new_business_case)
    } else {
      state.business_cases.push(new_business_case)
    }
  },
  REMOVE_BUSINESS_CASE(state, business_case_id) {
    let business_case_index = state.business_cases.findIndex(
      business_case => business_case.id === parseInt(business_case_id)
    )

    state.business_cases.splice(business_case_index, 1)
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let business_cases = await this.$axios.$get('business_cases', {
      params: params,
      withCredentials: true,
    })

    commit('SET_BUSINESS_CASES', business_cases.data)

    return business_cases.data
  },
  async fetch({ commit }, { business_case_id, params = null }) {
    let business_case = await this.$axios.$get(
      'business_cases/' + business_case_id,
      {
        params: params,
        withCredentials: true,
      }
    )

    commit('PUSH_OR_REPLACE_BUSINESS_CASE', business_case.data)

    return business_case.data
  },
  async store({ commit }, business_case) {
    business_case = await this.$axios.$post('business_cases', business_case, {
      withCredentials: true,
    })

    commit('PUSH_OR_REPLACE_BUSINESS_CASE', business_case.data)

    return business_case.data
  },
  async update({ commit }, { business_case, business_case_id }) {
    business_case = await this.$axios.$patch(
      'business_cases/' + business_case_id,
      business_case,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_BUSINESS_CASE', business_case.data)

    return business_case.data
  },
  async delete({ commit }, business_case_id) {
    await this.$axios.$delete('business_cases/' + business_case_id, {
      withCredentials: true,
    })

    commit('REMOVE_BUSINESS_CASE', business_case_id)
  },
}
