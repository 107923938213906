export const state = () => ({
  labels: [],
})

export const getters = {
  getAll: state => {
    return state.labels
  },
  get: state => label_id => {
    return state.labels.find(label => label.id === parseInt(label_id))
  },
}

export const mutations = {
  SET_LABELS(state, labels) {
    state.labels = labels
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let labels = await this.$axios.$get('labels', {
      params: params,
      withCredentials: true,
    })

    commit('SET_LABELS', labels.data)

    return labels.data
  },
}
