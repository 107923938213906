export const state = () => ({
  customer_products: [],
})

export const getters = {
  getAll: state => {
    return state.customer_products
  },
  get: state => customer_product_id => {
    return state.customer_products.find(
      customer_product => customer_product.id === parseInt(customer_product_id)
    )
  },
}

export const mutations = {
  SET_CUSTOMER_PRODUCTS(state, customer_products) {
    state.customer_products = customer_products
  },
  PUSH_OR_REPLACE_CUSTOMER_PRODUCT(state, new_customer_product) {
    let customer_product_index = state.customer_products.findIndex(
      customer_product =>
        customer_product.id === parseInt(new_customer_product.id)
    )

    if (customer_product_index >= 0) {
      state.customer_products.splice(
        customer_product_index,
        1,
        new_customer_product
      )
    } else {
      state.customer_products.push(new_customer_product)
    }
  },
  REMOVE_CUSTOMER_PRODUCT(state, customer_product_id) {
    let customer_product_index = state.customer_products.findIndex(
      customer_product => customer_product.id === parseInt(customer_product_id)
    )

    state.customer_products.splice(customer_product_index, 1)
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let customer_products = await this.$axios.$get('customer_products', {
      params: params,
      withCredentials: true,
    })

    commit('SET_CUSTOMER_PRODUCTS', customer_products.data)

    return customer_products.data
  },
  async fetch({ commit }, { customer_product_id, params = null }) {
    let customer_product = await this.$axios.$get(
      'customer_products/' + customer_product_id,
      {
        params: params,
        withCredentials: true,
      }
    )

    commit('PUSH_OR_REPLACE_CUSTOMER_PRODUCT', customer_product.data)

    return customer_product.data
  },
  async store({ commit }, customer_product) {
    customer_product = await this.$axios.$post(
      'customer_products',
      customer_product,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_CUSTOMER_PRODUCT', customer_product.data)

    return customer_product.data
  },
  async update({ commit }, { customer_product, customer_product_id }) {
    customer_product = await this.$axios.$put(
      'customer_products/' + customer_product_id,
      customer_product,
      { withCredentials: true }
    )

    commit('PUSH_OR_REPLACE_CUSTOMER_PRODUCT', customer_product.data)

    return customer_product.data
  },
  async delete({ commit }, customer_product_id) {
    await this.$axios.$delete('customer_products/' + customer_product_id, {
      withCredentials: true,
    })

    commit('REMOVE_CUSTOMER_PRODUCT', customer_product_id)
  },
}
