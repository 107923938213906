const middleware = {}

middleware['appInit'] = require('../middleware/appInit.js')
middleware['appInit'] = middleware['appInit'].default || middleware['appInit']

middleware['before-auth'] = require('../middleware/before-auth.js')
middleware['before-auth'] = middleware['before-auth'].default || middleware['before-auth']

middleware['clear'] = require('../middleware/clear.js')
middleware['clear'] = middleware['clear'].default || middleware['clear']

middleware['context'] = require('../middleware/context.js')
middleware['context'] = middleware['context'].default || middleware['context']

middleware['project-in-context'] = require('../middleware/project-in-context.js')
middleware['project-in-context'] = middleware['project-in-context'].default || middleware['project-in-context']

middleware['UnAuth'] = require('../middleware/UnAuth.js')
middleware['UnAuth'] = middleware['UnAuth'].default || middleware['UnAuth']

export default middleware
