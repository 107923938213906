export default function ({ store, from, redirect, route }) {
  let projectInContext = store.getters['context/projectInContext']

  if (!projectInContext) {
    alert('Není nastaven projekt v globálním kontextu.')

    if (from.fullPath === route.fullPath) {
      return redirect('/product_categories')
    } else {
      return redirect(from.fullPath)
    }
  }
}
