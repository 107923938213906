export default ({ $axios, store }) => {
  $axios.onError(error => {
    let code = parseInt(error.response && error.response.status)

    if (code === 422) {
      store.commit('validation/SET_ERRORS', error.response.data.errors)
    }

    if (code >= 400 && code !== 422) {
      let message = error.message + ': ' + error.response.data.message

      store.commit('SET_ERROR_MESSAGE', message)
    }
  })
}
