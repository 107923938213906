<template>
  <div class="row align-items-center mb-3 mb-md-4">
    <div class="col-auto mr-auto order-first">
      <h1 class="mb-0">{{ title }}</h1>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>
