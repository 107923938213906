export const state = () => ({
  // Options for selects
  selects: [
    {
      name: 'actions',
      options: [
        { key: 0, value: 'Připravuje se' },
        { key: 1, value: 'Připraveno' },
        { key: 2, value: 'Zahájeno' },
        { key: 3, value: 'Ukončeno' },
        { key: 4, value: 'Převzato' },
        { key: 5, value: 'Zrušeno' },
        { key: 6, value: 'Pozastaveno' },
      ],
    },
    {
      name: 'meeting_notes',
      options: [
        { key: 0, value: 'Připravuje se' },
        { key: 1, value: 'Připraveno' },
        { key: 2, value: 'Zahájeno' },
        { key: 3, value: 'Ukončeno' },
        { key: 4, value: 'Převzato' },
        { key: 5, value: 'Zrušeno' },
        { key: 6, value: 'Pozastaveno' },
      ],
    },
  ],
})

export const getters = {
  getOptions: state => entity_name => {
    return state.selects.filter(entity => entity.name === entity_name)[0]
      .options
  },
  getOptionName: (state, getters) => (entity_name, option_value) => {
    let options = getters.getOptions(entity_name)

    return options.filter(option => option.key === parseInt(option_value))[0]
      .value
  },
}

export const mutations = {}

export const actions = {}
