import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
import { ValidationProvider, extend } from 'vee-validate'
import vueDebounce from 'vue-debounce'

const global = {
  c_asset(path) {
    if (process.env.ASSET_URL === undefined) {
      return path
    }
    return process.env.ASSET_URL + '/dist' + path
  },
  getDescendantProp(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  },
  userCan(user, roles) {
    return typeof user === 'object' && roles.includes(user.role)
  },
}

// this is to help Webstorm with autocomplete
Vue.prototype.$global = global

Vue.use(VueSignaturePad)
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(vueDebounce)

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf())
  date.setDate(date.getDate() + parseInt(days))
  return date
}

Number.prototype.pad = function (size) {
  var s = String(this)
  while (s.length < (size || 2)) {
    s = '0' + s
  }
  return s
}

export default ({ app }, inject) => {
  inject('global', global)

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  inject('toBase64', toBase64)
}
