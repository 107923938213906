export default function ({ store, $auth, route, router, $localForage }) {
  // This should be limited only for old "offline" part.
  let allowedPages = ['tasks', 'tasks-create', 'tasks-id-edit']
  // TODO apply this middleware on those pages ?

  if (allowedPages.includes(route.name)) {
    return new Promise(async (resolve, reject) => {
      console.log('Promise')

      if (navigator.onLine) {
        // Fetch user from server
      } else {
        // Try to get user from $localForage

        // TODO hash out him ?
        let user = await $localForage.getItem('auth-user')

        if (user) {
          $auth.setUser(user)
        } else {
          $auth.setUser(null)
        }
      }

      // If I am online
      if (navigator.onLine) {
        console.log('Online')

        // console.log('AppInit - auth: ',
        //     $auth,
        //     $auth.loggedIn,
        //     $auth.user,
        //     );
        // Logged in
        if ($auth.loggedIn) {
          console.log('LoggedIn')
          // App is initialized
          if (store.getters['isAppInitialized']) {
            console.log('Already initilaized')
            // Fetch only new or modified records
            store.dispatch('appUpdate')
            resolve()
          } else {
            console.log('Before initialization')

            // Page was reloaded, but IDB holds the data
            if (await $localForage.getItem('initialized')) {
              console.log('Page was reloaded (VUEX empty)')

              await store.dispatch('appLoadFromIDB')
              store.dispatch('appUpdate')
              resolve()
            } else {
              // Fetch whole app
              await store.dispatch('appInit')
              resolve()
            }
          }
        } else {
          console.log('NOT logged in')
          resolve()
        }
      } else {
        // Offline
        console.log('Offline')

        // Logged In
        if ($auth.loggedIn) {
          console.log('LoggedIn')
          // App is initialized
          if (store.getters['isAppInitialized']) {
            console.log('Already initialized')
            // Vuex is filled. We are got to go.

            resolve()
          } else {
            console.log('Before initialization')

            // Page was reloaded, but IDB holds the data
            if (await $localForage.getItem('initialized')) {
              console.log('Page was reloaded (VUEX empty)')

              await store.dispatch('appLoadFromIDB')
              resolve()
            } else {
              // IDB was cleared. App is gone. Can't fetch anything from outside.
              // Well, redirect to login ?
              router.push('/')
              resolve()
            }
          }
        } else {
          console.log('NOT logged in')
          // NOTHING
          resolve()
        }
      }
    })
  }
}
