export default class Map {
  map = null // SMap instance
  layer = null

  /* Spočítat pozici mapy tak, aby byl vidět celý mnohoúhelník */
  // var cz = m.computeCenterZoom(souradnice);
  // m.setCenterZoom(cz[0], cz[1]);

  // suggest
  // https://api.mapy.cz/view?page=suggestbasic

  // Initialize map with center point
  initializeMap(containerID, center) {
    if (!this.map) {
      let m = new SMap(JAK.gel(containerID), center, 8) // m.getZoomRange();
      m.addDefaultLayer(SMap.DEF_BASE).enable()
      m.addDefaultControls()

      // Dynamic size of map
      var sync = new SMap.Control.Sync({ bottomSpace: 24 })
      m.addControl(sync)

      this.map = m
    } else {
      // If map exists, just change center.
      this.setCenter(center)
    }
  }

  destroyMap() {
    if (this.map) {
      this.map.$destructor()

      this.map = null
    }
  }

  // Modify center point of existing map
  setCenter(point) {
    // If there are coords => create point
    if (point.lat && point.lng) {
      point = this.createCoordinates(point.lat, point.lng)
    }

    this.map.setCenter(point)
  }

  createCoordinates(lat, lng) {
    // (lonD, latD) = lng, lat
    return SMap.Coords.fromWGS84(lng, lat)
  }

  createInactiveLayer() {
    this.layer = new SMap.Layer.Marker()
  }

  createClusterer() {
    let clusterer = new SMap.Marker.Clusterer(this.map)
    this.layer.setClusterer(clusterer)
  }

  // Adds one or many markers to layer.
  addMarkers(markers) {
    this.layer.addMarker(markers)
  }

  activateLayer() {
    this.map.addLayer(this.layer).enable()
  }

  // Remove all markers from layer
  clearMap() {
    this.layer.removeAll()
  }

  createCard(header) {
    let card = new SMap.Card()
    card.getHeader().innerHTML = header

    return card
  }

  createMarker(card, point, id, color = 'red') {
    let colorMarker = JAK.mel('div')
    let colorImage = JAK.mel('img', {
      src: SMap.CONFIG.img + '/marker/drop-' + color + '.png',
    })
    colorMarker.appendChild(colorImage)

    let marker = new SMap.Marker(point, id, {
      title: 'Informace o zákazníkovi',
      url: colorMarker,
    })

    marker.decorate(SMap.Marker.Feature.Card, card)

    return marker
  }

  geocode(query, callback) {
    new SMap.Geocoder(
      query,
      geocoder => {
        if (!geocoder.getResults()[0].results.length) {
          callback(null)
          return
        }

        let results = geocoder.getResults()[0].results

        callback(results[0])
      },
      {
        count: 1,
      }
    )
  }
}
