export const state = () => ({
  employees: [],
})

export const getters = {
  getAll: state => {
    return state.employees
  },
  get: state => id => {
    return state.employees.find(
      customer_employee => customer_employee.id === parseInt(id)
    )
  },
}

export const mutations = {
  SET_CUSTOMER_EMPLOYEES(state, employees) {
    state.employees = employees
  },
  PUSH_OR_REPLACE_EMPLOYEE(state, new_employee) {
    let employeeIndex = state.employees.findIndex(
      employee => employee.id === parseInt(new_employee.id)
    )

    if (employeeIndex >= 0) {
      state.employees.splice(employeeIndex, 1, new_employee)
    } else {
      state.employees.push(new_employee)
    }
  },
}

export const actions = {
  async fetchForOffline({ dispatch, commit }, params = null) {
    let employees = await fetchAll(
      { axios: this.$axios, dispatch, commit },
      params
    )

    this.$localForage.setItem('employees', employees)

    return employees
  },
  async fetch({ dispatch, commit }, params = null) {
    return await fetchAll({ axios: this.$axios, dispatch, commit }, params)
  },

  async fetchNewOrModifiedForOffline(
    { dispatch, commit, getters, rootGetters },
    params = {}
  ) {
    if (navigator.onLine) {
      params.fetched_at = await dispatch('getLastFetchedAt', 'employees', {
        root: true,
      })

      let employees = await this.$axios.$get('customer_employees', {
        params: params,
        withCredentials: true,
      })

      // Push new employees
      for (let employee of employees.data) {
        commit('PUSH_OR_REPLACE_EMPLOYEE', employee)
      }

      // Persist them
      this.$localForage.setItem('customer_employees', getters.getAll)
      // Set timestamp of last fetching
      dispatch('setFetchedAt', 'employees', { root: true })

      return employees.data
    }
  },
  async store({ commit }, employee) {
    // It's written to departure table
    await this.$axios.$post('customer_employees', employee, {
      withCredentials: true,
    })
  },
  async update({ commit }, { employee, employee_id }) {
    // It's written to departure table
    await this.$axios.$put('customer_employees/' + employee_id, employee, {
      withCredentials: true,
    })
  },
  async loadFromIndexDB({ commit }) {
    let customer_employees = await this.$localForage.getItem(
      'customer_employees'
    )

    commit('SET_CUSTOMER_EMPLOYEES', customer_employees)
  },
}

async function fetchAll({ axios, dispatch, commit }, params) {
  if (navigator.onLine) {
    let employees = await axios.$get('customer_employees', {
      params: params,
      withCredentials: true,
    })

    // Set employees
    commit('SET_CUSTOMER_EMPLOYEES', employees.data)
    // Set timestamp of last fetching
    dispatch('setFetchedAt', 'employees', { root: true })

    return employees.data
  }
}
