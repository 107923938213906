export default function ({ $axios, redirect, store }) {
  // console.log('UnAuth middleware',
  //     // store.$auth,
  //     store.$auth.loggedIn,
  //     store.$auth.user,
  //     );

  // if (getCookie('X-XSRF-TOKEN'))
  // Just to set up X-XSRF-TOKEN to cookies.
  $axios.request({
    url: '/users/auth',
    method: 'get',
    propertyName: 'data',
    withCredentials: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    },
  })
  if (store.$auth.loggedIn) {
    return redirect('/tasks')
  } else {
  }
}
